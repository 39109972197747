*{
  box-sizing: border-box;
}

body{
  margin: 0;
  background-color: #232323;
  color: #eb5f07;
  font-family: Calibri, sans-serif;
}

.center{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

p{
  width: 70%;
  font-size: 30px;
  display: block;
  text-align: center;
}

.char{
  font-size: 100px;
  height: 100px;
  animation: an 1s ease-out 1 both;
  display: inline-block;
}

@keyframes an{
  from{
    opacity: 0;
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to{
    opacity: 1;
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
